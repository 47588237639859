.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
  }

  .top-bar__title {
    text-decoration: none;
    color: black;
  }

  .top-bar__user-info {
    display: flex;
    align-items: center;
  }

  .top-bar__welcome {
    margin-right: 10px;
  }

  .top-bar__logout-button {
    padding: 8px 16px;
    background-color: #0078D4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
