.container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th,
.table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:nth-child(odd) {
    background-color: #ffffff;
}